import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Box, DataLoading } from '@bottomless/common/components';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { assignScaleAction } from '../../../store';
import { useMe } from '../../../hooks/useMe.hook';
import { X } from 'react-feather';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const CHOOSE_ACCOUNT_SUFIX = '/choose-account';

const ConnectCompletePageComponent = ({ assignScale }) => {
  const me = useMe();
  const history = useHistory();
  const location = useLocation();
  const isChooseAccount = useMemo(() => location.pathname.includes(CHOOSE_ACCOUNT_SUFIX), [location]);

  const [error, setError] = useState(null);

  useEffect(() => {
    if (isChooseAccount && me.accounts.length > 1) {
      return history.push({
        pathname: '/change-account',
        state: { path: `${location.pathname.replace(CHOOSE_ACCOUNT_SUFIX, '')}` },
      });
    }

    (async () => {
      try {
        const { error } = await assignScale({ account: me._id });
        if (error) {
          setError(error);
        } else {
          history.push('/activate/success?flow=first_step');
        }
      } catch (e) {
        // Work around for the case when customer goes through assignment flow 2nd time
        if (e.details?.base_id && e.details?.base_id === me.base_id) {
          history.push('/activate/success?flow=first_step');
        }
        setError(e);
      }
    })();
  }, [assignScale, me._id, me.base_id, me.accounts, history, isChooseAccount, location.pathname]);

  return (
    <div className="page-activate-success">
      <h1 className="text-center mb-4">Scale Activation</h1>
      <Box secondary className="mb-4">
        <DataLoading
          count={0}
          isLoading={!error}
          withMarginTop={false}
          loadingText="Connecting scale to your account"
        />
        {error && (
          <div>
            <h2 className="text-center mb-5">Scale activation failed.</h2>
            <div className="d-flex justify-content-center mb-3">
              <span className="text-white success-checkmark bg-danger d-flex align-items-center justify-content-center rounded-circle">
                <X className="mt-1" />
              </span>
            </div>
            <div className="text-center">
              <div className="text-uppercase small text-secondary">Error</div>
              <div className="font-weight-bold scale-status-text">{error.message}</div>
            </div>
          </div>
        )}
      </Box>

      {error && me.accounts?.length > 1 && (
        <Link
          to={{ pathname: '/change-account', state: { path: location.pathname.replace(CHOOSE_ACCOUNT_SUFIX, '') } }}
          className="btn btn-lg btn-dark btn-block"
        >
          Switch Account
        </Link>
      )}
    </div>
  );
};

ConnectCompletePageComponent.propTypes = {
  assignScale: PropTypes.func.isRequired,
};

export const ConnectCompletePage = withMetaTags({
  title: 'Bottomless.com: Activate your smart scale',
})(
  connect(null, (dispatch, { match }) => ({
    assignScale: data => dispatch(assignScaleAction(match.params.id, data)),
  }))(ConnectCompletePageComponent)
);
